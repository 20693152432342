import React from "react";

export default function BookCard({ img, member, title }) {
  return (
    <div className="flex flex-row w-full md:w-96 h-40 rounded shadow-md cursor-pointer hover:bg-blue-50 hover:shadow-lg">
      <img className="h-40 object-cover" src={img} alt="Book Cover" />
      <div className="flex flex-col flex-grow justify-between px-6 py-3">
        <div className="text-xl text-gray-700 font-extralight font-serif line-clamp-3">
          {title}
        </div>
        <div className="text-md font-light text-gray-400 text-right">
          {member}
        </div>
      </div>
    </div>
  );
}
