import React from "react";
import BookCard from "../components/BookCard";
import Container from "../components/Container";

const BOOKS = [
  {
    title: "Good Economics for Hard Times",
    member: "Pete",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1562348201l/51014619._SX318_SY475_.jpg",
  },
  {
    title: "Green Lights",
    member: "Will",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1604281659l/52838315._SX318_.jpg",
  },
  {
    title: "Light",
    member: "Tim",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1389700041l/17735.jpg",
  },
  {
    title: "The Autobiography of Benjamin Franklin",
    member: "Larry",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1428170518l/52309.jpg",
  },
  {
    title: "Man's Search for Meaning",
    member: "John",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1535419394l/4069._SY475_.jpg",
  },
  {
    title:
      "Liftoff: Elon Musk and the Desperate Early Days That Launched SpaceX",
    member: "Brian",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1602088633l/53402132._SY475_.jpg",
  },
  {
    title: "The Master and Margarita",
    member: "TK",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327867963l/117833.jpg",
  },
  {
    title: "Under a White Sky: The Nature of the Future",
    member: "Pete",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1608039192l/54814834.jpg",
  },
  {
    title: "Klara and the Sun",
    member: "Will",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1603206535l/54120408._SY475_.jpg",
  },
  {
    title:
      "The Snakehead: An Epic Tale of the Chinatown Underworld and the American Dream",
    member: "Will",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1320472165l/6004724.jpg",
  },
  {
    title: "Radicalized",
    member: "Doug",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1547668215l/41447687._SY475_.jpg",
  },
  {
    title: "Luminarium",
    member: "Tim",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1320563914l/10148470.jpg",
  },
  {
    title: "Leviathan Wakes",
    member: "John",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1411013134l/8855321.jpg",
  },
  {
    title:
      "Tastes of Paradise: A Social History of Spices, Stimulants, and Intoxicants",
    member: "Brian",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1320520689i/141602.jpg",
  },
  {
    title: "Agent Zigzag: A True Story of Nazi Espionage, Love, and Betrayal",
    member: "TK",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1320497271l/655627.jpg",
  },
  {
    title: "After Dark",
    member: "Pete",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1437952316i/17803.jpg",
  },
  {
    title: "Go Tell It on the Mountain",
    member: "Will",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1657560979l/18248426._SY475_.jpg",
  },
  {
    title: "The Crying of Lot 49",
    member: "Doug",
    img: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1657594162l/2794._SY475_.jpg",
  },
  {
    title: "But What If We're Wrong?",
    member: "Tim",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1454284600i/27068734.jpg",
  },
  {
    title: "Cruddy",
    member: "John",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1442887874i/29015.jpg",
  },
  {
    title: "Tomorrow, and Tomorrow, and Tomorrow",
    member: "Brian",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1636978687i/58784475.jpg",
  },
  {
    title: "Dead Souls",
    member: "TK",
    img: "https://m.media-amazon.com/images/I/61CQoYM9giL._SL1170_.jpg",
  },
  {
    title: "VALIS",
    member: "Pete",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1388200579i/216377.jpg",
  },
  {
    title: "The Wager: A Tale of Shipwreck, Mutiny and Murder",
    member: "Will",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1659407155i/61714633.jpg",
  },
  {
    title: "Jesus's Son",
    member: "Doug",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1702372714i/608287.jpg",
  },
  {
    title: "The Dinosaurs: New Visions of a Lost World",
    member: "Tim",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1624656390i/57392598.jpg",
  },
  {
    title: "Nothing to Envy: Ordinary Lives in North Korea",
    member: "John",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1529606621i/40604846.jpg",
  },
  {
    title:
      "Prisoners of Geography: Ten Maps That Tell You Everything You Need to Know About Global Politics",
    member: "Brian",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1432827094i/25135194.jpg",
  },
  {
    title: "Lonesome Dove",
    member: "TK",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1559668037i/256008.jpg",
  },
  {
    title: "Austerlitz",
    member: "Pete",
    img: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1516499623i/88442.jpg",
  },
];

export default function IndexPage() {
  return (
    <Container>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 pt-8 pb-16">
        {BOOKS.map((book, index) => (
          <BookCard key={index} {...book} />
        ))}
      </div>
    </Container>
  );
}
